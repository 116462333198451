<template>
  <div class="case-changzhou">
    <Nav :isFlexible="false" />
    <div class="banner">
      <el-image
        style="width: 100%"
        :style="{ height: clientHeight + 'px' }"
        fit="cover"
        lazy="true"
        :src="BgUrl"
      >
      </el-image>
    </div>
    <section id="sub-header">
      <div class="sub-header">
        <h1>{{ $t("case-changzhou.title") }}</h1>
      </div>
    </section>
    <section id="product" class="section-dark">
      <div class="product">
        <!-- <div class="product-title title" data-inviewport>
          数字化运营
          <div class="line"></div>
        </div> -->
        <div class="product-subtitle subtitle">
          {{ $t("case-changzhou.info") }}
          <div class="line-grey"></div>
        </div>
        <div class="product-detail-wrapper">
          <div
            v-for="p in products"
            :key="p.name"
            class="product-detail"
            data-inviewport
          >
            <div class="inner">
              <div class="product-detail-content">
                <div class="product-detail-image">
                  <el-image
                    style=""
                    fit="cover"
                    :src="this.PicBaseUrl + p.image + '?imageView2/0/w/1200'"
                  >
                  </el-image>
                </div>
                <div class="product-detail-title">{{ p.name }}</div>
                <!-- <div class="product-detail-subtitle">
                  {{ p.remark }}
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "CaseChangzhou",
  components: {
    Nav,
    Footer,
  },
  data() {
    return {
      init: false,
      clientHeight: 0,
      PicBaseUrl: "//image.nexmaterials.com.cn/",
      BgUrl: "//image.nexmaterials.com.cn/bg-blur-1.jpg",
    };
  },
  computed: {
    products: function () {
      return [
        {
          name: this.$t("case-changzhou.detail[0].title"),
          image: "changzhou-productline.jpg",
          remark: this.$t("case-changzhou.detail[0].subtitle"),
        },
        {
          name: this.$t("case-changzhou.detail[1].title"),
          image: "changzhou-monitor.jpg",
          remark: this.$t("case-changzhou.detail[1].subtitle"),
        },
        {
          name: this.$t("case-changzhou.detail[2].title"),
          image: "changhzou-inspection.jpg",
          remark: this.$t("case-changzhou.detail[2].subtitle"),
        },
      ];
    },
  },
  mounted() {
    // window.addEventListener("scroll", this.scrollToTop);
    this.clientHeight = `${document.documentElement.clientHeight}`; //获取浏览器可视区域高度
    window.addEventListener("resize", () => {
      this.clientHeight = `${document.documentElement.clientHeight}`;
      // console.log(this.clientHeight);
    });
  },
};
</script>
<style lang="scss">
.case-changzhou {
  .product {
    .el-image__inner {
      transition: all 0.3s;
    }
    .el-image__inner:hover {
      transform: scale(1.5);
    }
  }
}
</style>
<style lang="scss" scoped>
$main-color: #47a6db;
$main-bgcolor: #1a1a1a;
$font-color: #fff;
$font-default: 16px;
$line-height-default: 24px;
$font-title: 40px;
$font-subtitle: 16px;
$font-detail-title: 20px;
$font-detail-subtitle: 16px;
$margin-bottom-default: 10px;
.case-changzhou {
  .title {
    font-size: $font-title;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 5px;
    text-align: center;
    .line {
      position: relative;
    }
    .line::after {
      content: " ";
      position: absolute;
      width: 100px;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      border: 1px solid $main-color;
    }
  }
  .section-dark {
    width: 100%;
    background-color: $main-bgcolor;
    color: #fff;
    position: relative;
    z-index: 10;
    padding: 90px 0;
  }
  .banner {
    position: fixed;
    top: 0;
    left: 0;
    // height: 1000px;
    width: 100%;
    z-index: 1;
  }
  .sub-header {
    position: relative;
    padding: 90px 0;
    z-index: 2;
    color: $font-color;
    h1 {
      font-size: 40px;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 5px;
      margin-top: 60px;
      text-align: center;
    }
  }
  #section {
  }
  .product {
    @media (min-width: 768px) {
      width: 700px;
    }
    @media (min-width: 992px) {
      width: 970px;
    }
    @media (min-width: 1200px) {
      width: 1170px;
    }
    position: relative;
    z-index: 10;
    background-color: $main-bgcolor;
    margin-left: auto;
    margin-right: auto;
    .product-title {
      padding: 60px 0 20px;
      font-size: 40px;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 5px;
      text-align: center;
    }
    .product-title.is-inViewport {
      animation: fadeInLeft 1s;
    }

    .product-subtitle {
      margin: 40px 0;
      padding: 20px 60px 80px;
      line-height: $line-height-default;
      letter-spacing: 2px;
      font-size: $font-subtitle;
      visibility: visible;
      .line-grey {
        position: relative;
      }
      .line-grey::after {
        content: " ";
        position: absolute;
        width: 80%;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
        border: 1px solid #2b2b2b;
      }
    }
    .product-detail-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // width: 100%;
      justify-content: center;
      padding: 0 10px 60px;
      margin-left: auto;
      margin-right: auto;
      .product-detail:nth-child(3n + 1).is-inViewport {
        animation: fadeInLeft 1s;
      }
      .product-detail:nth-child(3n + 2).is-inViewport {
        animation: fadeInBottom 1s;
      }
      .product-detail:nth-child(3n + 3).is-inViewport {
        animation: fadeInRight 1s;
      }
      .product-detail {
        @media (min-width: 992px) {
          width: 33.33333%;
        }
        box-sizing: border-box;
        // padding: 0 15px;
        // margin-left: 10px;
        // margin-right: 10px;
        .inner {
          margin-bottom: 30px;
          padding: 20px;
          // background-color: #1e1e1e;
          // background-color: green;
          // display: flex;
          // flex-direction: row;
          // justify-content: center;
          .product-detail-content {
            @media only screen and (max-width: 767px) {
              width: 100%;
            }
            @media (min-width: 992px) {
              width: 350px;
            }
            // padding-left: 25px;
            text-align: left;
            // width: 350px;
            .product-detail-image {
              overflow: hidden;

              .el-image {
                border-radius: 6px;
                width: 100%;
                height: 200px;
                transition: all 0.3s;
              }
              .el-image__inner:hover {
                transform: scale(1.5);
              }
            }
            .product-detail-title {
              margin-top: $margin-bottom-default;
              font-size: $font-detail-title;
              line-height: $line-height-default;
              margin-bottom: $margin-bottom-default;
            }
            .product-detail-subtitle {
              width: 100%;
              word-wrap: break-word;
              // height: 70px;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              font-size: $font-detail-subtitle;
              line-height: $line-height-default;
            }
          }
        }
      }
    }
  }
}
</style>

